<template>
  <div class="view pa24">
    <div>
      <div class="mt20 d-flex ju-end" v-if="$route.query.id ? false : true">
        <el-button @click="save(0)" type="primary">保存到草稿箱</el-button>
        <el-button @click="save(1)" type="primary">发布</el-button>
      </div>
      <div class="mt20 d-flex ju-end" v-else>
        <el-button type="primary" @click="save(draftType)">保存编辑</el-button>
      </div>
    </div>
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="auto">
      <el-form-item label="添加3D高定商品" v-if="$route.query.id ? false : true">
        <el-button type="primary" @click="centerDialogVisible = true">+添加商品</el-button>
      </el-form-item>

      <el-form-item label="选择高级定制商品模型" v-if="tableData.length > 0">
        <div class="flex">
          <el-select v-model="ruleForm.tookType" placeholder="请选择平台类型" @change="changeExploreType" style="width: 200px">
            <el-option :disabled="item.id == 0 ? false : true" v-for="item in typeList" :key="item.id" :label="item.title"
              :value="item.id">
            </el-option>
          </el-select>
          <el-select class="ml-6" v-model="ruleForm.selectClass" placeholder="请选择分类" @change="changeClassfiy">
            <el-option v-for="item in classfiyList" :key="item.typeId" :label="item.labelName" :value="item.typeId">
            </el-option>
          </el-select>
          <el-select class="ml-6" v-model="exploreId" placeholder="请选择内容" @change="changeModel">
            <el-option v-for="(item, index) in modelList" :key="index" :label="item.title" :value="item.id">
              {{ item.title }}
            </el-option>
          </el-select>
          <div class="ml-6" v-if="exploreId && ruleForm.exploreUrl">
            <a style="background:#35455b;display: block;" class="text-center co-ff w-100 h-33 ra-4" target="_blank"
              :href="ruleForm.exploreUrl">预览</a>
          </div>
        </div>

      </el-form-item>
    </el-form>
    <commonTable :tableData="tableData" :total="tableData.length" :paginationVisible='false'>
      <template v-slot:table>
        <el-table-column prop="goodsId" align="center" label="产品ID" show-overflow-tooltip />
        <el-table-column prop="goodsName" align="center" label="产品" />
        <el-table-column align="center" prop="goodsTypeName" label="分类" />
        <el-table-column prop="price" align="center" label="价格">
          <template slot-scope="scope">
            {{ (scope.row[$route.query.id ? 'minPrice' : 'price'] / 100).toFixed(2) }}
          </template>
        </el-table-column>
        <el-table-column prop="stockNum" align="center" label="库存" />
        <el-table-column :prop="$route.query.id ? 'totalBuyNum' : 'dealNum'" align="center" label="总销量" />
        <el-table-column align="center" label="发布时间">
          <template slot-scope="scope">
            {{ setGetDataTimeSec(scope.row.createTime) }}
          </template>
        </el-table-column>
      </template>
    </commonTable>

    <p class="">选择商品规格</p>
    <div class="table-box">
      <commonTable :tableData="specList" :total="specList.length">
        <template v-slot:table>
          <el-table-column prop="goodsName" align="center" label="商品名称" show-overflow-tooltip />
          <el-table-column prop="typeName" align="center" label="商品类型" />
          <el-table-column align="center" prop="specAttribute" label="商品规格" />
          <el-table-column prop="stockNum" align="center" label="商品库存" />
          <el-table-column prop="price" align="center" label="价格">
            <template slot-scope="scope">
              {{ (scope.row.price / 100).toFixed(2) }}
            </template>
          </el-table-column>
          <el-table-column align="center" label="是否组件">
            <template slot-scope="scope">
              <el-switch :value="setSpec[scope.$index].isModule == 1 ? true : false"
                @change="(e) => switchChange(e, scope.$index)">
              </el-switch>
            </template>
          </el-table-column>
          <el-table-column align="center" label="缩略图">
            <template slot-scope="scope">
              <div @click="openUpload(scope.$index, 'photo')">
                <img style="border:1px solid #999999;" class="w50 h50 ra-8" :src="setSpec[scope.$index].photo" alt="">
              </div>
            </template>
          </el-table-column>
          <el-table-column align="center" label="高清缩略图">
            <template slot-scope="scope">
              <div @click="openUpload(scope.$index, 'hdPhoto')">
                <img style="border:1px solid #999999;" class="w50 h50 ra-8" :src="setSpec[scope.$index].hdPhoto" alt="">
              </div>
            </template>
          </el-table-column>
          <el-table-column align="center" label="操作" width="300">
            <div slot-scope="scope">
              <el-select v-model="setSpec[scope.$index].modelId" @change="changeItemModel($event, scope.$index)">
                <el-option v-for="(item, index) in sourceList" :key="index" :label="item.title" :value="item.id">
                  {{ item.title }}
                </el-option>
              </el-select>
            </div>
          </el-table-column>
        </template>
      </commonTable>
    </div>

    <el-dialog title="缩略图" :visible.sync="dialogVisibleThumbnail" width="50%" :modal-append-to-body="false"
      :destroy-on-close="true" center>
      <el-form label-width="50">
        <el-form-item label="上传缩略图">
          <el-upload :class="{
            'avatar-uploader': true,
            'avatar-coverImg': true,
            disabled: uploadDisabled,
          }" ref="uploadIcon" :action="'http://f3.ttkt.cc:17526/file/uploadObjectOSS'" list-type="picture-card" :on-progress="handProgress"
            :on-success="handleAvatarSuccessCoverImg" :on-remove="handleRemove" :file-list="fileList" :limit="1"
            accept=".png,.jpg">
            <i class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
          <div class="el-upload__tip">
            *格式png、jpg，大小不超过2MB。
          </div>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleThumbnail = false">取 消</el-button>
        <el-button type="primary" @click="uploadSure">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="选择商品" :visible.sync="centerDialogVisible" :modal-append-to-body="false" :destroy-on-close="true"
      width="60%" center>
      <product v-if="centerDialogVisible" ref="product" />
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="determine">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import product from "./product";
import { getDataTimeSec } from "@/utils";
import { addGoodsInfo, updateGoodsInfo } from "@/api/3dProduct";

import { blendQueryPage, typeQueryPage, materialQueryPage } from "@/api/drawingBed";
export default {
  data() {
    return {
      dialogVisibleThumbnail: false,
      uploadIndex: '',
      uploadType: '',
      exploreId: '',
      fileList: [],
      isUpdate: false,
      modelList: [],
      ruleForm: {
        killType: "", // 活动类型
        pickerValue: "",
        maxPurchase: "",
      },

      pickerOptions: {
        disabledDate(time) {
          return time.getTime() <= Date.now() - 24 * 60 * 60 * 1000;
        },
      },
      rules: {
        modelId: [{ required: true, message: "请选择模型" }],
      },
      types: [
        { label: "限时秒杀", id: 1 },
        { label: "促销秒杀", id: 2 },
      ],
      tableData: [],
      specList: [],
      setSpec: [],
      centerDialogVisible: false,
      draftType: '',
      killNo: '',//编号
      typeList: [
        {
          title: "图壳应用",
          id: 0,
        },
        {
          title: "图壳模型",
          id: 1,
        },
        {
          title: "近迁全景",
          id: 2,
        },
      ],
      classfiyList: [],
      sourceList: [],
    };
  },
  computed: {
    setGetDataTimeSec() {
      return (val) => getDataTimeSec(val);
    },
    uploadDisabled() {
      return this.fileList.length !== 0;
    },
  },
  components: {
    commonTable,
    product,
  },
  created() {
    const { id } = this.$route.query;
    if (id) {
      this.updateKillGoodsInfo(id);
    }
    this.getSourceList();
  },
  mounted() {
    // this.getapplyList();
  },
  methods: {
    getSourceList() {
      let data = {
        pageNum: 1,
        pageSize: 100,
      };
      materialQueryPage(data).then((res) => {
        this.sourceList = res.data.records;
      });
    },
    changeExploreType(e) {
      if (e == 0) {
        this.getClassfiyList();
      }
    },
    // 获取分类列表
    getClassfiyList() {
      let data = {
        pageNum: 1,
        pageSize: 1000,
      };
      typeQueryPage(data).then((res) => {
        this.classfiyList = res.data.records;
      });
    },
    changeClassfiy(e) {
      this.ruleForm.exploreId = '';
      this.ruleForm.exploreUrl = '';
      this.getapplyList(e);
    },
    uploadSure() {
      this.dialogVisibleThumbnail = false;
      this.setSpec = JSON.parse(JSON.stringify(this.setSpec))
    },
    openUpload(index, type) {
      this.dialogVisibleThumbnail = true;
      this.uploadIndex = index;
      this.uploadType = type;
      this.fileList = [];
    },
    changeItemModel(e, index) {
      this.sourceList.forEach((el) => {
        if (el.id == e) {
          this.setSpec[index].modelName = el.realUrl;
        }
      });
    },
    switchChange(e, index) {
      this.setSpec[index].isModule = e ? 1 : 0;
    },
    changeModel(e) {
      this.modelList.forEach((el) => {
        if (el.id == e) {
          this.ruleForm.exploreUrl = el.realUrl;
        }
      });
    },
    getapplyList(id) {
      let data = {
        pageNum: 1,
        pageSize: 100,
        typeId: id,
      };
      blendQueryPage(data).then((res) => {
        this.modelList = res.data.records;
      });
    },
    determine() {
      const { multipleSelection } = this.$refs.product;
      if (multipleSelection.isExplore == 1) {
        this.getClassfiyList();
        this.getapplyList(multipleSelection.selectClass);
        this.ruleForm.tookType = multipleSelection.tookType;
        this.ruleForm.selectClass = multipleSelection.selectClass;
        this.ruleForm.exploreUrl = multipleSelection.exploreUrl;
        this.exploreId = multipleSelection.exploreId;
      }
      const arr = [];
      this.setSpec = [];
      this.tableData = [multipleSelection];

      for (let i = 0; i < multipleSelection.goodSpecModelList.length; i++) {
        let type = multipleSelection.goodSpecModelList[i];
        for (let j = 0; j < type.goodSpecModelList.length; j++) {
          let spec = type.goodSpecModelList[j];
          spec.goodsName = multipleSelection.goodsName;
          spec.typeName = type.specName;
          spec.stockNum = multipleSelection.stockNum;
          this.setSpec.push({
            killPrice: "",
            killNum: "",
            stockNum: spec.stockNum,
            price: spec.price,
            specId: spec.specId,
          });
          arr.push(spec);
        }
      }
      this.specList = arr;
      this.centerDialogVisible = false;
    },
    /**@method  发布*/
    save(type) {
      if (this.tableData.length == 0) {
        this.$message({
          message: "请先选择商品",
          type: "warning",
        });
        return;
      }
      let temp = this.setSpec.map((item) => {
        return {
          specId: item.specId,
          modelId: item.modelId,
          modelName: item.modelName,
          isModule: item.isModule,
          hdPhoto: item.hdPhoto,
          photo: item.photo,
        };
      });
      let data = {
        hauteCoutureId: this.tableData[0].hauteCoutureId,
        draftType: type,
        goodSpecRequestList: temp,
        hauteCoutureNo: this.hauteCoutureNo,
        goodsId: this.tableData[0].goodsId,
        exploreUrl: this.ruleForm.exploreUrl,
        exploreId: this.exploreId,
        tookType: this.ruleForm.tookType,
        selectClass: this.ruleForm.selectClass,
        isExplore: 1,
        // startTime: `${this.ruleForm.pickerValue[0]} 00:00:00`,
        // endTime:`${ this.ruleForm.pickerValue[1]} 23:59:59`,
        hauteCoutureype: this.ruleForm.hauteCoutureType,
        maxPurchase: this.ruleForm.maxPurchase,
        updateType: this.$route.query?.drafts == '1' ? 1 : 0,
      };
      console.log(data, '提交的数据');
      if (this.$route.query.id) delete data.hauteCoutureNo;
      if (!this.$route.query.id) delete data.hauteCoutureId;
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          try {
            await addGoodsInfo(data);
            this.$message({
              message: type == 1 ? "发布成功" : "保存成功",
              type: "success",
            });
            setTimeout(() => {
              this.$router.go(-1);
            }, 1000);
          } catch (error) {
            if (error.code == 201) {
              this.$message.error(error.message);
            } else {
              this.$message.error(type == 1 ? error.message || "发布失败" : "保存失败");
            }
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    /**@method 获取编辑内容 */
    async updateKillGoodsInfo(id) {
      const result = await updateGoodsInfo({ hauteCoutureId: id }, { showLoading: true });
      console.log(result, 'result*********');
      const { exploreId, exploreUrl, tookType, selectClass, startTime, endTime, maxPurchase, goodSpecModelList, goodsName, stockNum, killNo, draftType } = result.data;
      this.getClassfiyList();
      this.getapplyList(selectClass);
      this.exploreId = exploreId;
      this.ruleForm.exploreUrl = exploreUrl;
      this.ruleForm.selectClass = selectClass;
      this.ruleForm.tookType = tookType;
      this.ruleForm.pickerValue = [this.setGetDataTimeSec(startTime), this.setGetDataTimeSec(endTime)];
      this.ruleForm.maxPurchase = maxPurchase;
      this.tableData = [result.data];
      this.killNo = killNo;
      this.draftType = draftType;
      const arr = [];
      this.setSpec = [];
      for (let i = 0; i < goodSpecModelList.length; i++) {
        let type = goodSpecModelList[i];
        for (let j = 0; j < type.goodSpecModelList.length; j++) {
          let spec = type.goodSpecModelList[j];
          spec.goodsName = goodsName;
          spec.typeName = type.specName;
          spec.stockNum = stockNum;
          this.setSpec.push({
            killPrice: spec.killPrice / 100,
            modelId: spec.modelId,
            modelName: spec.modelName,
            isModule: spec.isModule || 0,
            killNum: spec.killNum,
            photo: spec.photo,
            hdPhoto: spec.hdPhoto,
            stockNum: spec.stockNum,
            price: spec.price,
            specId: spec.specId,
          });
          arr.push(spec);
        }
      }
      this.specList = arr;
    },
    /**@method 上传时回调 */
    handProgress(event, file, fileList) {
      if (this.isUpdate) {
        this.$message.closeAll();
        this.$message({
          message: "正在上传",
        });
      }
      this.isUpdate = false;
    },
    // 封面
    handleAvatarSuccessCoverImg(res, file, fileList) {
      this.fileList = fileList;
      this.$message.closeAll();
      this.$message({
        message: "上传成功",
        type: "success",
      });
      if (this.uploadType == 'photo') {
        this.setSpec[this.uploadIndex].photo = `https://cdn-oss.jquen.com/${res.data}`;
      } else {
        this.setSpec[this.uploadIndex].hdPhoto = `https://cdn-oss.jquen.com/${res.data}`;
      }
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
      if (this.uploadType == 'photo') {
        this.setSpec[this.uploadIndex].photo = '';
      } else {
        this.setSpec[this.uploadIndex].hdPhoto = '';
      }
      this.fileList = [];
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .table-box .el-table {
  max-height: calc(100vh - 500px);
  overflow-y: scroll;
}

::v-deep .disabled .el-upload--picture-card {
  display: none;
}
</style>
